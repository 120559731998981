@import "../../assets/scss/partials/pallet";

$height: 60px;
$fontSize: 14px;

.navbar {
    background: $color_IMT_White;
    padding: 0;
    z-index: 11;
    .logout {
        // margin-left: auto;
    }
    a {
        text-align: center;
        position: relative;
    }
    .navbar_wrapper {
        display: flex;
        width: 100%;
        max-width: 1440px;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        flex-direction: column;
        > div {
            width: 82.1%;
            // 900px
            @media (max-width: 900px) {
                width: 100%;
                padding: 0px 15px 0px 15px;
            }
            margin-left: auto;
            margin-right: auto;
            height: $height;
            display: flex;
            align-items: center;
            gap: 1.7em;
            // font-size: $fontSize;
            position: relative;
            > .active {
                color: $primary;
                img {
                    filter: $filter_primary;
                }
            }
        }
    }

    .navbar_primary {
        .navbar_primary_logo {
            height: 50% !important;
            width: auto !important;
            padding-right: 2.2em;
            > img {
                height: 100% !important;
                width: auto !important;
            }
        }
        .navbar_primary_logo.increasedHeight {
            height: 80% !important;
        }
        img {
            margin-right: 0.3em;
            // width: 16px;
        }
    }

    .navbar_secondary {
        color: $headings;
        height: calc(#{$height} -2px);
        img {
            margin-right: 0.3em;
            width: 16px;
        }
        > div {
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .navbar_secondary_companyName {
            color: $primary;
            display: flex;
            align-items: center;
            width: 10em;
            > div {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                // height: 1em;
            }
            max-height: 60px;
            // create arrow to right
            > span {
                display: inline-block;
                margin-left: 0.5em;
                margin-right: 0.5em;
                border-top: 1px solid $primary;
                border-right: 1px solid $primary;
                height: 0.5em;
                width: 0.6em;
                transform: rotate(45deg);
                transform-origin: 0 0;

                &:hover {
                    display: none;
                }
            }

            &.long {
                :hover {
                    position: absolute;
                    top: 0;
                    left: -1em;
                    background: $color_IMT_White;
                    border-radius: 5px;
                    overflow: visible;
                    width: 10em;
                    // height: auto;
                    overflow: hidden;
                    white-space: normal;
                    padding: 1em 0 1em 1em;

                    + span {
                        display: none;
                    }
                }
            }
        }
        > .active {
            border-bottom: solid 2px $primary;
            color: $primary;
            img {
                filter: $filter_primary;
            }
        }

        a:hover {
            color: $primary;
        }
    }

    .navbar_secondary_dropdown {
        height: 100%;
        display: flex;
        align-items: center;
        .navbar_secondary_dropdown_head {
            > span {
                display: inline-block;
                margin-left: 0.5em;
                border-top: 1px solid;
                border-right: 1px solid;
                height: 0.5em;
                width: 0.5em;
                transform: rotate(135deg);
                transform-origin: 0 0;
            }
            .secondIcon {
                margin-right: 0;
            }
            cursor: pointer;
        }
        &:hover {
            .navbar_secondary_dropdown_list {
                position: absolute;
                top: 40px;
                display: flex;
                flex-direction: column;
                justify-content: left;
                background: $color_IMT_White;
                border-radius: 0.5em;
                min-width: 7em;
                z-index: 1;
                box-shadow: 0px 0px 5px 0px $color_N50;
                > * {
                    padding: 0.5em;
                    text-align: left;
                }
                a {
                    &:hover {
                        background-color: rgb(250, 250, 250);
                    }
                }
            }
        }
        .navbar_secondary_dropdown_list {
            display: none;
            .active {
                color: $primary;
                border-bottom: solid 2px $primary;
            }
        }
    }

    .navbar_secondary_dropdown_list_wrapper {
        display: none;
        // .navbar_secondary_dropdown_list_mobile{
        //     display: none;
        // }
    }
    .navbar_secondary_mobileWrapper {
        position: fixed !important;
        // z-index: 999;
        bottom: 0;
        width: 100vw !important;
        background: $color_IMT_White;

        .navbar_secondary_dropdown_head {
            text-align: center;
        }
        .navbar_secondary {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 1em;
            .active {
                border-bottom: solid 2px $color_IMT_White;
            }
        }
    }

    .navbar_secondary_dropdown_mobile:hover {
        .navbar_secondary_dropdown_list_wrapper {
            display: flex;
            // min-height: calc(100vh - #{$height});
            background: $color_N50;
            position: fixed;
            bottom: 60px;
            left: 0;
            // &:hover{
            //     display: none;
            // }
            // position: relative;
            // z-index: 999;
        }
        .navbar_secondary_dropdown_list_mobile {
            margin-top: auto;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 1.5em;
            padding: 1em;
            background: $color_IMT_White;
            border-radius: em em 0 0;
            width: 100vw;
            > a {
            }
        }
    }

    // 900px
    @media (min-width: 900px) {
        .desktop {
            img {
                width: 16px;
            }
        }
        .mobile {
            display: none;
            z-index: 100;
        }
    }
    @media (max-width: 900px) {
        .desktop {
            display: none;
        }
        .mobile {
            // .navbar_secondary{
            img {
                width: 24px;
            }
            // }
        }
    }

    .topbar_logout {
        color: $topbar_logout;
        img {
            filter: $topbar_logout_filter;
        }
    }
    .navChip {
        position: absolute;
        top: -1.2em;
        right: 0;
        background-color: #01a76f;
        border-radius: 100px;
        color: $color_IMT_White;
        transform: translate(50%, 0);
        // remove white space wrap
        white-space: nowrap;
        padding-right: 1em;
        padding-left: 1em;
    }
    // do less transform in mobile
    @media (max-width: 900px) {
        .navChip {
            transform: translate(30%, 0);
        }
    }
}
