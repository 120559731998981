@import "../../assets/scss/partials/pallet";

.microsoftLogin {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 10px !important;
    margin-top: 0 !important;
    height: 42px !important;
    font-family: Roboto, sans-serif;
    color: $color_N700 !important;
    border: 1px solid $color_N200 !important;
    box-shadow: none !important;
    font-weight: 500 !important;
    border-radius: 24px !important;
    display: flex;
    flex-direction: row-reverse;
    .microsoftLogin_icon {
        height: 22px !important;
    }
}
