.kmdStep3 {
    .kmdStep3_review {
        .kmdStep3_header {
            display: flex;
            justify-content: space-between;
            width: 100%;
        }
        .kmdStep2_member {
            display: flex;
            flex-direction: column;
        }
        .kmdStep3_personalDetails {
            display: flex;
            flex-wrap: wrap;
            .kmdStep3_pesonalDetail {
                display: flex;
                flex-direction: column;
                width: 45%;
            }
        }
        .kmdStep3_memberDetails {
            display: flex;
            flex-wrap: wrap;
            .kmdStep3_member {
                display: flex;
                flex-direction: column;
                width: 45%;
                .kmdStep3_memberInfo {
                    display: flex;
                    gap: 5px;
                }
            }
        }
    }
}
