@import "../../assets/scss/partials/pallet";
.unsubscribe__container {
    position: relative;
    left: 0;
    top: 15vh;
    min-height: 70vh;
    border-radius: 1rem;
    margin-inline: 10rem;
    min-width: 80vh;
}
.unsubscribe {
    position: absolute;
    border-radius: 1rem;
    background-color: $color_IMT_White;
    padding: 50px;
    top: 0;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
}
.unsubscribe::after {
    content: "";
    position: absolute;
    top: 100%;
    background-color: (0 0% 100% / 0);
    width: 100%;
    height: 15vh;
}
.unsubscribe input[type="radio"] {
    display: inline;
    height: auto !important;
    margin-inline: 10px;
    margin-top: 10px;
}
.unsubscribe label {
    color: $headings_font;
    display: inline;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 20px;
    margin-bottom: 12px;
}
.unsubscribe button {
    width: 200px;
    margin-top: 20px;
    margin-bottom: 60px;
}
.unsubscribe__logo {
    width: 80px;
    position: absolute;
    right: 40px;
    top: 30px;
}
.unsubscribe__head {
    margin-bottom: 0;
}
.unsubscribe__foot {
    max-width: 60%;
}
.unsubscribe p {
    color: $color_IMT_Black_font;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 20px;
}
.unsubscribe__head p {
    font-weight: 500;
}
.unsubscribe__head h1 {
    font-weight: 500;
    font-size: 1.75rem;
    line-height: 42px;
    margin-bottom: 20px;
}
.unsubscribe__byeimage {
    /*position: absolute;
  margin: 0 100px 100px 0;
  bottom: 0;
  right: 0;*/
    width: 219px;
}
@media (max-width: 1080px) {
    .unsubscribe__logo {
        position: absolute;
        top: -15vh;
        left: 20px;
    }
    .unsubscribe__container {
        min-width: 90vw;
        margin-inline: auto;
        top: 15vh;
    }
    .unsubscribe {
        margin-top: 2rem;
        margin-inline: 5vw;
        padding: 2rem;
        width: 90vw;
    }
    .unsubscribe__head h1 {
        font-size: 1.25rem;
    }
    .unsubscribe__head p {
        font-size: 0.75rem;
    }
    .unsubscribe label {
        font-size: 0.75rem;
    }
    .unsubscribe__byeimage {
        max-width: 219px;
        margin-inline: auto;
    }
    .unsubscribe__foot {
        max-width: 100%;
    }
    .unsubscribe button {
        margin-inline: auto;
    }
}

.unsubscribe a {
    color: blue;
    text-decoration: underline;
}
.circle {
    width: 128px;
    aspect-ratio: 1;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $color_Acc_Blue;
    margin-bottom: 40px;
}
.circle img {
    width: 70px;
    aspect-ratio: 1;
}
