@import "../../../assets/scss/partials/pallet";

.kmdStep1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 645px;
    @media (max-width: 820px) {
        width: 90vw;
    }

    .kmdStep1_header {
        display: flex;
        background-color: $color_N100;
        border-radius: 10px;
        justify-content: space-between;
        width: 100%;
        .kmdStep1_header_content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .kmdStep1_premium {
                height: 56px;
            }
            .kmdStep1_sumInsured {
                width: 200px;
            }
        }
        @media (max-width: 820px) {
            width: 90vw;
        }
        @media (max-width: 467px) {
            flex-direction: column;
            .kmdStep1_header_content {
                .kmdStep1_premium {
                    height: 56px;
                }
                .kmdStep1_sumInsured {
                    width: 100%;
                }
            }
        }
    }
    > button {
        width: 100%;
    }
}
