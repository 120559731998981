.addonSteps_box {
    border-radius: 20px;
    overflow: hidden;
    .modal-container {
        position: unset;
        background-color: white;
        padding: 0;
    }

    button {
        width: 100%;
    }
}

.addonSteps_title {
    position: relative;
    h1 {
        width: 80%;
        font-weight: 600;
    }
}

.addonSteps_brandImage {
    background: white;
    width: 47px;
    height: 47px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 27px;
    top: 0;
    img{
        width: 32px;
        height: 32px;
    }
}

.addonSteps_background {
    position: absolute;
    right: 27px;
    width: 70px;
}

.addonSteps_container {
    h2 {
        font-weight: 600;
    }
}

.addonSteps_knowMore {
    height: 220px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    img {
        width: 88px;
    }
}
