@import "../../assets/scss/partials/pallet";

.goog-logo-link {
    display: none !important;
}
.goog-te-gadget {
    color: transparent !important;
    a {
        display: none !important;
    }
}

.goog-tooltip {
    display: none !important;
}

.goog-te-banner-frame.skiptranslate {
    display: none !important;
}

body > .skiptranslate {
    display: none;
    iframe {
        height: 0 !important;
        width: 0 !important;
        z-index: -10000001 !important;
        position: absolute !important;
        border: none !important;
        box-shadow: none !important;
    }
}

#google_translate_element {
    // min-width: 200px;
    float: right;
    text-align: right;
    display: block;
    margin-top: 10px;
}
.body-container_v2{
    .google_translate_element_container{
        #google_translate_element {
            width: 100% !important;
            margin: 0;
    
            select{
                width: 100%;
                border-radius: 24px;
                border: none !important;
                border-right: 16px solid transparent !important;
                color: black !important;
                outline: none !important;
            }
        }
    }
}

.goog-te-banner-frame.skiptranslate {
    display: none !important;
}
body {
    top: 0px !important;
}
#goog-gt-tt {
    display: none !important;
    top: 0px !important;
}
.goog-tooltip skiptranslate {
    display: none !important;
    top: 0px !important;
}
.activity-root {
    display: hide !important;
}
.status-message {
    display: hide !important;
}
.started-activity-container {
    display: hide !important;
}
.goog-te-combo {
    font-size: 10pt !important;
    vertical-align: baseline;
    color: $primary !important;
    border: 1px solid $primary !important;
    border-radius: 5px;
    padding: 5px 10px;
    height: 2.1rem;
    width: 11em;
    margin: 0 !important;
    float: inline-end;
}

.goog-te-combo-arrow {
    display: none;
}

.goog-te-gadget {
    white-space: nowrap;
}

.goog-te-menu-value {
    font-size: 10pt;
    padding: 5px;
}

.goog-te-menu-value:hover {
    background-color: #f0f0f0;
    cursor: pointer;
}
