@import "../../../assets/scss/partials/pallet";

$radius: 16px;
// $width: calc((100% - 1em)/2);
// $widthS: 100%;
// $widthR: 350px;
// $widthRS: 100%;
$buttonHeight: 33px;
$buttonRadius: 8px;
$buttonWidth: 150px;
$X: 14px;
$Y: 23px;
$partnerBackground: white;
$partnerSide: 47px;
$partnerImageSize: 32px;

.addon {
    display: flex;
    flex-direction: column;
    border-radius: $radius;
    // width: $width;
    // @media (max-width: 520px) {
    //   width: $widthS;
    // }
    justify-content: space-between;
    position: relative;

    h1,
    h2 {
        width: 80%;
        font-weight: 600;
        z-index: 1;
    }

    .addon_button {
        height: $buttonHeight;
        border: none;
        border-radius: $buttonRadius;
        width: $buttonWidth;
        z-index: 1;
    }

    .addon_background {
        position: absolute;
        right: $X;
        bottom: $Y;
        z-index: 0;
        width: 70px;
    }

    .addon_partner {
        position: absolute;
        right: $X;
        top: 0;
        background: $partnerBackground;
        width: $partnerSide;
        height: $partnerSide;
        display: flex;
        justify-content: center;
        align-items: center;
        .addon_partner_image {
            width: $partnerImageSize;
            height: $partnerImageSize;
        }
    }
    .addon_chip {
        position: absolute;
        right: calc($X + 3px);
        top: calc($X - 1px);
        z-index: 1000;
        height: 22px;
    }
}

.addon.kmdCard {
    .addon_button_container {
        width: 100% !important;
    }
    .kmdCardImg {
        width: 120px;
        margin-bottom: 55px;
    }
}

// .home_contentMain{
//     .addon{

//     }
// }

// .home_contentSide{
//   .addon{
//     width: $widthR;
//     @media (max-width: 520px) {
//       width: $widthRS;
//     }
//   }
// }
