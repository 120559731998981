.steps_step {
    display: flex;
    .hyperLink{
        display: inline;
        font-size: 14px;
    }
}

.steps_step_text {
    width: 100% ;
    display: inline-block;
}