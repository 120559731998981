.rightColumnCard {
    .addon__Skeleton {
        .addon_partner {
            background: transparent;
        }
        .addon_background {
            bottom: 20px;
        }
    }
}
