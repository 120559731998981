@import "../../assets/scss/partials/pallet";

$height: 0px;
$borderRadius: 6px;
$fontSize: 14px;
$customDDHeight: 40px;

.custom-dd {
    position: relative;
    // margin-top: 20px !important;
    margin-bottom: 19px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    border-radius: $borderRadius;
    background: $background;
    height: $customDDHeight;
    &.inverted {
        background: $color_IMT_White;
        .dd-list {
            background: $color_IMT_White;
            .item:hover {
                background: $background;
            }
        }
    }
    color: $base_font;
    font-size: $fontSize;
    .dd-title {
        position: absolute;
        top: -20px;
        margin-bottom: 4px;
        color: $tableContents;
    }
    .dd-list-wrapper {
        min-height: $height;
        height: $customDDHeight;
    }
    .dd-one-item {
        min-height: $height;
        display: flex;
        align-items: center;
        padding: 9px;
        padding-left: 16px;
        padding-right: 16px;
        border-radius: $borderRadius;
    }
    .dd-selected {
        min-height: $height;
        display: flex;
        align-items: center;
        padding: 9px;
        padding-left: 16px;
        padding-right: 16px;
        border-radius: $borderRadius;
        &::after {
            // arrow at right
            content: "";
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 5px solid $tableContents;
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
        }
    }
    .dd-list {
        position: absolute;
        // max-width: 15em;
        overflow: auto;
        width: 100%;
        height: 0;
        transition: height 1s ease-in-out;
        // overflow: hidden;
        background: $background;
        z-index: 10;
        max-height: 15em;
        // overflow: scroll;
        border-radius: 0 0 $borderRadius $borderRadius;
        &.show {
            height: auto;
        }
        > div {
            padding: 9px;
            padding-left: 16px;
            padding-right: 16px;
            display: flex;
            align-items: center;
            min-height: $height;
            height: $customDDHeight;
        }
        .item:hover {
            background: $color_IMT_White;
        }
    }
}
.dropdownTag {
    background-color: green;
    color: white;
    border-radius: 1000px;
}
.max {
    margin-bottom: 3px;
    .dd-selected {
        padding-right: 25px;
    }
    .dd-list {
        width: auto;
    }
    .dd-list-wrapper {
        background: white;
    }
}
fieldset {
    legend {
        width: unset;
    }
}

.standard-noborder:after,
.standard-noborder:before {
    border-bottom: none !important;
}
.standard-noborder {
    > div:focus {
        background-color: $color_IMT_White;
    }
}
