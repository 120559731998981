// @import "../assets/scss/partials/pallet";

// .Toastify__toast-theme--colored.Toastify__toast--success {
//     background-color: $color_S800;
//     color: $color_IMT_White;
// }

// .Toastify__toast--error {
//     background-color: $color_E800;
//     color: $color_IMT_White;
// }

// .Toastify__toast--warning {
//     background-color: $color_W600;
//     color: $color_IMT_Black;
// }

// .Toastify__toast--info {
//     background-color: $color_P500;
//     color: $color_IMT_White;
// }

// // for screen greater than 768px

// @media (min-width: 768px) {
//     .Toastify__toast-container {
//         width: 300px;
//         // height: 50px;
//     }
// }

// .Toastify__toast {
//     border-radius: 12px;
//     padding: 8px 16px;
// }

// .Toastify__toast-body > div > svg {
//     width: 20px;
//     height: 20px;
// }
// .Toastify__toast-body > div:nth-child(2) {
//     width: 194px;
//     margin: 0 4px;
// }

.Toastify__toast {
    min-height: auto;
    padding: 0;
    border-radius: 8px;
}

.Toastify__toast-body {
    padding: 0;
    border-radius: 8px;
    // in mobile width will be 100% and in desktop it will be 300px
    width: 100%;
    @media screen and (min-width: 768px) {
        width: 420px;
    }
}
