@import "../../../assets/scss/partials/pallet";

.emp-nav-link {
    display: flex;
    align-items: center;
    width: 120px;
    h6 {
        color: #0c1230;
        margin-bottom: 0;
    }
    &:hover {
        color: #0c1230;
    }

    .benefit_wallet {
        &::after {
            // content: '₹5459';
            position: absolute;
            left: 100%;
            top: 0px;
            transform: translate(-100%, -90%);
            border-radius: 12px;
            background-color: $primary;
            color: white;
            font-size: 10px;
            font-weight: 400;
            padding: 0px 8px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

.active-link {
    position: relative;
    color: $primary;

    h6 {
        color: $primary;
    }

    &:hover {
        color: $primary;
    }

    &::before {
        content: "";
        position: absolute;
        left: -36px;
        top: 50%;
        transform: translateY(-50%);
        width: 6px;
        height: 76px;
        background-color: $primary;
        border-radius: 0px 20px 20px 0px;
    }
}

.emp-nav-link.mobile-navbar {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .benefit_wallet {
        &::after {
            background-color: #05c168;
            z-index: 10;
        }
    }
}

.active-link.mobile-navbar {
    &::before {
        content: "";
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
        top: -10px;
        width: 72px;
        height: 6px;
        background-color: $primary;
        border-radius: 0px 0px 20px 20px;
        z-index: 9;
    }
}
