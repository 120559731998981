@import "../../assets/scss/partials/pallet";
.loader {
    display: block !important;
    height: 100% !important;
    /*position: relative;*/
}
.loader__loading {
    /*width: calc(100% + 3rem);
  height: calc(100% + 3rem);
  top: -0.6rem;
  left: -1.5rem;*/
    position: absolute;
    background: $color_IMT_White;
    z-index: 999;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}
.loader__popup {
    background: $color_IMT_White;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 990;
    /*width: calc(100% + 3rem);
  height: calc(100% + 3rem);
  top: -0.6rem;
  left: -1.5rem;*/
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}
.loader__popup__circle {
    width: 128px;
    aspect-ratio: 1;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.bg--green {
    background-color: $color_Acc_Blue;
}
.bg--red {
    background-color: $color_C100;
}
.loader__popup__image {
    width: 50%;
    aspect-ratio: 1;
}
.loader__title {
    display: flex;
    justify-content: center;
    font-size: 16px;
    margin: 20px;
    color: $color_N900;
}
.loader__subtext {
    color: $color_N800;
}

.loader-container {
    position: relative;
    overflow: hidden;
}
