@import "pallet";
@import url("https://fonts.googleapis.com/css2?family=Chivo+Mono&display=swap");

h1 {
    font-weight: 500;
    font-size: 24px;
    // line-height: 36px;
    color: $headings_font;
}

h2 {
    font-weight: 500;
    font-size: 16px;
    // line-height: 24px;
    color: $headings_font;
}

h3 {
    font-size: 14px;
    // line-height: 20px;
    color: $headings_font;
}

.fontSize_14 {
    font-size: 14px;
}
.fontSize_14I {
    font-size: 14px !important;
}

.fontSize_12 {
    font-size: 12px;
}

.fontSize_10 {
    font-size: 10px;
}

.fontSize_16 {
    font-size: 16px;
}

.fontSize_18 {
    font-size: 19px;
}

.fontSize_20 {
    font-size: 20px;
}

.fontSize_24 {
    font-size: 24px;
}

.fontSize_28 {
    font-size: 28px;
}

.fontSize_32 {
    font-size: 32px;
}

.fontSize_40 {
    font-size: 40px;
}

.fontColor_dark {
    color: $color_IMT_Black_font !important;
}

.fontColor_white {
    color: $color_IMT_White_font;
}

.fontColor_red {
    color: $error_font;
}

.fontColor_primary {
    color: $primary_font !important;
}

.primaryColor {
    color: $primary_font !important;
}

.bolder {
    font-weight: 500;
}

.fontWeight_500 {
    font-weight: 500;
}

.fontWeight_600 {
    font-weight: 600;
}

.fontWeight_700 {
    font-weight: 700;
}
// mobile text
// @for $i from 1 through 3{
//     h#{$i}{
//         color: $headings;
//         @media (max-width: 767px) {
//             font-size: 18px;
//             // line-height: 24px;
//         }
//     }
// }
@media (max-width: 768px) {
    h1 {
        font-size: 18px;
    }
}

button {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal !important;
    font-size: 14px !important;
    // line-height: 20px;
}

body {
    font-size: 12px;
    // line-height: 18px;
    color: $base_font;
    p {
        font-size: 12px;
        // line-height: 18px;
        color: $base_font;
    }

    // for mobile
    @media (max-width: 767px) {
        font-size: 10px;
        // line-height: 15px;
    }

    label {
        font-size: 12px;
    }
}

.fs14 {
    font-size: 14px;
}

.hyperLink {
    font-size: 12px;
    // line-height: 16px;
    color: $hyperLink !important;
    cursor: pointer;
}

.underline {
    text-decoration: underline;
}

table {
    th {
        font-weight: 500 !important;
        font-size: 12px !important;
        // line-height: 20px !important;
        letter-spacing: 0.1px !important;
        color: $headings_font !important;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        vertical-align: middle !important;
    }
    td {
        font-size: 12px !important;
        // line-height: 18px !important;
        color: $tableContents !important;

        // for mobile
        @media (max-width: 767px) {
            font-size: 10px !important;
            // line-height: 15px !important;
        }
    }
}

.headings_font_color {
    color: $headings_font;
}
.headings_white_color {
    color: $color_IMT_White;
}
.headings_N800_color {
    color: #3e3c47;
}
// $sizes : 12px, 14px, 16px, 18px, 24px;
// $screens : sm, md, lg, xl;

// @each $size in $sizes{
//     @each $screen in $screens{
//         .f#{$size}#{$screen}{
//             font-size: #{$size} !important;
// line-height: #{$size} !important;
//         }
//     }
// }

.textCenter {
    text-align: center;
}

// .monospace {
//   font-family: 'Chivo Mono', monospace;
// }
