@import "../../assets/scss/partials/pallet";

#correct,
#wrong {
    width: 100% !important;
}
#correct::before {
    content: url("https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/correct.svg");
}
#wrong::before {
    content: url("https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/wrong.svg");
}
.reset {
    height: 0px;
}
.reset::before {
    width: 10px !important;
    height: 10px;
    display: inline-block;
    position: relative;
    top: -52px;
    right: calc(-1 * 100% + 33px);
}
.password {
    height: 0px;
}
.password::before {
    width: 10px !important;
    height: 10px;
    display: inline-block;
    position: relative;
    top: -53px;
    right: calc(-1 * 100% + 35px);
}
#eyeopen,
#eyeclose {
    width: 100% !important;
    cursor: pointer;
}
#eyeopen::before {
    content: url("https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/eyeopen.svg");
}
#eyeclose::before {
    content: url("https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/eyeopen.svg");
}
.myText_fullWidth {
    width: 100% !important;
}

/* custom styles for Muis TextField  */
/* label  */
.MuiFormLabel-root.MuiInputLabel-root {
    color: $base_font;
}
.MuiFormLabel-root.MuiInputLabel-root.Mui-focused {
    color: $primary;
}
.MuiInputBase-root.MuiOutlinedInput-root {
    color: $headings;
}

/* readonly  */
.MuiInputBase-readOnly,
.Mui-focused.MuiInputBase-readOnly {
    background-color: $color_N100;
    color: $lightwhite !important;
}

.MuiFormControl-root .Mui-focused.MuiOutlinedInput-root > fieldset {
    border-color: $primary;
}

.MuiFormControl-root .Mui-readOnly.MuiOutlinedInput-root:hover > fieldset,
.MuiFormControl-root
    .Mui-focused.Mui-readOnly.MuiOutlinedInput-root
    > fieldset {
    border-color: $color_N50;
}
