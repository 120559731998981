// have follwing classes
// membersComponent
// memberComponent_member
// memberComponent_memberImageContainer
// memberComponent_memberImage
// memberComponent_memberDetailContainer
// membersComponent_memberName
// membersComponent_memberDobRelatioship
// memberComponent_memberButtons
// membersComponent_addMemberButton

// Make a card using the above classes
// card should be verticle image on top and details below
// image should be 100% width of the card
// image should be at bottom of image container
// image should be horizontally centered
// details should be 100% width of the card
// details should be vertically centered
// details should be horizontally centered
// edit and delete should be on the top right of the card
@import "../../assets///scss/partials/pallet";
.membersComponent {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: 0px 20px;
    gap: 2em;
}

// for mobile view less than 480px
@media (max-width: 480px) {
    .membersComponent {
        margin: 0;
        flex-direction: column;
    }
}

.memberComponent_member {
    display: flex;
    flex-direction: column;
    min-height: 183px;
    min-width: 183px;
    position: relative;

    // add very light and blured shadow
    box-shadow: 0px 0px 10px 0px $color_N400;

    border-radius: 4px;
}

// for mobile view less than 480px
@media (max-width: 480px) {
    .memberComponent_member {
        width: 100%;
        flex-direction: row;
        align-items: center;
        min-height: auto;
        padding: 5px;
    }
}

.memberComponent_memberImageContainer {
    background-color: $color_N100;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 112px;
}

// for mobile view less than 480px
@media (max-width: 480px) {
    .memberComponent_memberImageContainer {
        height: 50px;
        border-radius: 5px;
    }
}

.memberComponent_memberImage {
    height: 100%;
    width: auto;
    object-fit: cover;
}

.memberComponent_memberDetailContainer {
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 71px;
}

// for mobile view less than 480px
@media (max-width: 480px) {
    .memberComponent_memberDetailContainer {
        height: auto;
        padding: 0px 10px;
        align-items: baseline;
    }
}

.membersComponent_memberName {
    font-size: 14px;
    font-weight: 500;
    color: $color_N800;
}

.membersComponent_memberDobRelatioship {
    font-size: 12px;
    font-weight: 400;
    color: $color_N800;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 480px) {
    .membersComponent_memberDobRelatioship {
        justify-content: flex-start;
        flex-wrap: wrap;
    }
}

.memberComponent_memberButtons {
    position: absolute;
    top: 5px;
    right: 5px;
}

// for mobile view less than 480px
@media (max-width: 480px) {
    .memberComponent_memberButtons {
        position: relative;
        top: 0px;
        right: 0px;
        margin-left: auto;
    }
}

// make add member button have same dimensions as the member card
// add dashed border    and background color is white
// text should be bold
.membersComponent_addMemberButton {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 183px;
    min-width: 183px;
    border: 1px dashed $color_N800;
    border-radius: 4px;
    font-weight: 900 !important;
    color: $color_P500;
    background-color: white;
    gap: 2em;
}

// for mobile view less than 480px
@media (max-width: 480px) {
    .membersComponent_addMemberButton {
        width: 90%;
        min-height: auto;
        padding: 5px;
        flex-direction: row;
        align-items: center;
        height: 60px;
    }
}
