@import "../../assets/scss/partials/pallet";
.kmdInfo {
    // @media (max-width: 1024px) {
    //     h1 {
    //         font-size: 32px;
    //     }
    // }

    @media (max-width: 767px) {
        // h1 {
        //     font-size: 26px;
        //     text-align: center;
        // }
    }
    .kmdInfo_heroSection {
        display: flex;
        align-items: center;
        justify-content: center;
        .kmdInfo_heroText {
            button {
                font-weight: 500 !important;
            }
        }

        .kmdInfo_heroImage {
            img {
                width: 320px;
            }
        }
        width: 90%;
        margin: 20px auto;
        gap: 10vw;
        @media (max-width: 1024px) {
            gap: 20px;
            .kmdInfo_heroText {
            }

            .kmdInfo_heroImage {
                img {
                    width: 280px;
                }
            }
        }
        @media (max-width: 767px) {
            flex-direction: column-reverse;
            .kmdInfo_heroText {
                text-align: center;
                button {
                    margin: auto;
                }
            }

            .kmdInfo_heroImage {
                img {
                    width: 200px;
                }
            }
        }
    }

    .kmdInfo_coverageInfo {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: $color_P50;

        position: relative; // if not already positioned
        width: calc(
            100vw - 5px
        ); // assuming the parent's padding is 20px on each side
        left: calc(-1 * (100vw - 100%) / 2); // to center the div
        padding: 0 120px;
        @media (max-width: 1024px) {
            padding: 0 20px;
        }
        .kmdInfo_cardsContainer {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .kmdInfo_card {
                position: relative;
                display: flex;
                align-items: center;
                // justify-content: space-between;
                background-color: $color_IMT_White;
                padding: 20px;
                border-radius: 8px;
                margin-bottom: 20px;
                width: 49%;

                @media (max-width: 820px) {
                    width: 100%;
                }

                .kmdInfo_cardImage {
                    img {
                        width: 100px;
                        height: auto;
                        border-radius: 8px;
                    }
                }
                .kmdInfo_cardInfo {
                    position: absolute;
                    right: 10px;
                    top: 10px;
                }
            }
        }
    }

    .kmdInfo_form {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .kmdInfo_faqSection {
        display: flex;
        flex-direction: column;
        align-items: center;
        .kmdInfo_faqContainer {
            display: flex;
            gap: 20px;
            flex-direction: column;
            width: 100%;
            .kmdInfo_faq {
                height: 90px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $color_Acc_Purple;
                border-radius: 5px;
                p {
                    color: $color_IMT_Dark !important;
                    text-align: center;
                }
            }
        }
    }
}
