$primary: #0a2340;
$primary-purple: #4f3dd1;
$secondary-purple: #8172f1;
$tertiary-purple: #aea4fd;
$blue-black: #0a2341;
$blue: #2f80ed;

$gray-bg: #f6f6f6;
$gray-text: #999c9e;
$gray: #dfddf5;

$shade-1: #100a40;
$shade-2: #5d5a84;
$shade-3: #585570;
$shade-4: #828282;
$error: #ff8585;

$color_IMT_Black-3: #333;
$color_IMT_Black-6: #666;
$color_IMT_Black-9: #999;

$color_IMT_White: #fff;
$color_IMT_White-grey: #fafafa;
$color_IMT_White-gray-light: #e0e0e0;
$header-color: #4e5a61;
$yellow-white: #ffecc6;

$dark-yellow: #ea8c4c;
$light-yellow: #ea8c4c;
$pink: #f0b7b6;
$light-pink: #f2ccb3;
$green: #2db98a;
$light-green: #2db98a;
$light-blue: #b0d1fd;

$brown: #b1413f;

$global-width: 77vw;

//*************************** New Styles **********************

$purple-p: #4f3dd1;
$purple-s: #8172f1;
$purple-t: #aea4fd;
$purple-4: #5d5a84;

$blueblack-p: #0a2341;
$blueblack-s: #0a2340;

$blue-p: #2f80ed;
$blue-s: #b0d1fd;

$pink-p: #f0b7b6;
$pink-s: #f2ccb3;

$green-p: #2db98a;

$color_IMT_White-p: #fff;
$color_IMT_White-s: #fafafa;
$color_IMT_White-t: #e0e0e0;

$yellow-p: #ea8c4c;
$yellow-s: #ffecc6;

$gray-p: #dfddf5;
$gray-s: #4e5a61;
$gray-t: #828282;
$gray-4: #f6f6f6;

$brown-p: #b1413f;

$color_IMT_Black-p: #333;
$color_IMT_Black-s: #666;
$color_IMT_Black-t: #999;

$red-p: #ff8585;

$nav-bg: #2f4050;
$nav-bg-highlight: #293846;
$nav-faded: #a7b1c2;
$gray-text: #999c9e;

$global-width: 82.1vw;
