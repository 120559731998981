@import "../../assets/scss/partials/pallet";

.otp {
    height: 56px !important;
    width: 56px;
    text-align: center;
    font-size: 20px;
    caret-color: transparent;
    border-radius: 16px !important;
    border: 1px solid $color_N800;
    outline: none;
}

.otp-focused {
    border: solid $primary 2px;
}

.otpSubmit {
    display: block;
    margin-top: 20px;
    width: 100%;
    height: 45px;
    border-radius: 35px;
    background-color: $primary;
    color: white;
    border: none;
    font-weight: 500;
}
.otpInputContainer {
    display: flex;
    justify-content: space-between;
    min-width: 80%;
}
.otpError {
    border-color: red;
}
.otpError:focus {
    outline-color: red;
}
