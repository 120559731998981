@import "../../../../../assets/scss/partials/pallet";

.body-container_v2 {
    .claim-upload {
        position: relative;
        display: flex;
        flex-direction: column;
        // justify-content: space-between;
        height: 100%;
        // max-height: calc(100dvh - calc(110px + 4rem));
        border-radius: 16px;
        // max-height: calc(100vh - calc(60px + 2em));
        &.disableClaimUpload_View {
            opacity: 0.5;
            pointer-events: none;
        }
        @media (max-width: 1024px) {
            flex: none;
            // min-height: calc(100dvh - calc(120px + 2rem));
            min-height: calc(100dvh - calc(120px + 1rem));
            &.twoColumn_left {
                flex: none;
            }
        }
        @media (max-width: 768px) {
            flex: none;
            min-height: calc(100dvh - calc(120px + 2rem));
        }
        .viewLoader {
            position: absolute;
            width: 100%;
            height: 100%;
            display: grid;
            place-items: center;
            z-index: 1;
        }

        .claim-upload_top {
            background-color: $color_IMT_Black;
            border-top-left-radius: 16px;
            border-top-right-radius: 16px;
            .claim-upload_top_navigation {
                // padding: 2em 3em;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                color: $color_IMT_White;
                .icon {
                    cursor: pointer;
                    // height: 14px;
                    // width: auto;uploadDocuments_navigation_bar
                    height: 14px;
                }
                h1 {
                    flex-grow: 1;
                    color: $color_IMT_White;
                    z-index: 1;
                    // margin: 0;
                    font-size: 20px;
                    font-weight: 500;
                }
            }
            .claim-upload_top_banner {
                display: flex;
                align-items: center;
                .claim-upload_top_banner_image {
                    flex: 0 0 117px;
                    width: 117px;
                    height: 117px;
                    display: grid;
                    place-items: center;
                    img {
                        height: 117px;
                        width: auto;
                    }

                    @media (max-width: 768px) {
                        flex: 0 0 60px;
                        width: 60px;
                        height: 60px;
                        display: flex;
                        justify-content: center;
                        img {
                            // height: 120px;
                            // width: 120px;
                            // object-fit: none;
                            height: 120px;
                            width: auto;
                        }

                        &.uploadHome_banner_img {
                            // img {
                            //     height: 60px;
                            //     width: 60px;
                            //     // object-fit: ;
                            // }
                        }
                    }
                }
                .claim-upload_top_banner_text {
                    // flex: 75%;
                    margin: 0 20px;
                    padding-block: 1em;
                    h2 {
                        color: $color_IMT_White;
                        // font-size: 1.8em;
                    }
                    p {
                        font-size: 14px;
                        color: $lightwhite;
                        // margin-bottom: 0;
                    }
                    .uploadDocuments_banner_buttons {
                        display: flex;
                        flex-wrap: wrap;
                        // for mobile
                        @media (max-width: 768px) {
                            justify-content: center;
                        }
                        .uploadDocuments_cards_container {
                            padding: 0;
                            background-color: transparent;
                            .uploadDocuments_cardRow {
                                margin-block: 0;
                            }
                        }
                        .buttonTransparent {
                            border: none;
                            color: $color_IMT_White;
                            border: 1px solid $color_IMT_White;
                        }
                    }
                }

                // for mobile
                @media (max-width: 768px) {
                    flex-direction: column;
                    .claim-upload_top_banner_text {
                        margin-inline: 20px;
                        h2 {
                            font-size: unset;
                        }
                        p {
                            margin-bottom: 0;
                        }
                    }
                }

                @media (max-width: 480px) {
                    .claim_upload_top_banner_text {
                        p {
                            font-size: 12px;
                        }
                    }
                }
            }

            &.claim-upload_top_skeleton {
                background-color: $color_IMT_White;
                .claim-upload_top_banner {
                    .claim-upload_top_banner_text {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                    }
                }
            }
        }

        .claim-upload_body {
            // padding: 24px;
            // overflow-y: scroll;
            overflow: auto;
            // height: 400px;
            flex-grow: 1;
            // background-color: $color_IMT_White;
            .uploadDocuments_cardRow {
                display: flex;
                margin-block: 16px;
                .uploadDocuments_card {
                    position: relative;
                    flex: 1;
                    cursor: pointer;
                    min-height: 60px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 16px;
                    border-radius: 6px;
                    width: 100%;
                    box-shadow: 0px 4px 8px 3px $color_N400;
                    .uploadDocuments_card_icon {
                        &.icon_left {
                            > div {
                                position: relative;
                                width: 12px;
                                height: 12px;
                                border-radius: 50%;
                                border: 1px solid $base_font;
                            }
                            img {
                                width: 16px;
                                height: 16px;
                            }
                        }

                        &.profile_icon {
                            flex-grow: 60px;
                            width: 60px;
                            height: 60px;
                            background-color: $color_P50;
                            display: flex;
                            justify-content: center;
                            border-radius: 0.5em;
                            img {
                                max-width: 100%;
                                max-height: 100%;
                            }
                        }

                        p {
                            color: $color_IMT_Black;
                            font-size: 14px;
                            margin-bottom: 0.5em;
                        }
                    }
                    .uploadDocuments_card_text {
                        flex-grow: 1;
                        margin-left: 2em;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        h2 {
                            font-size: 16px;
                            margin-bottom: 0;
                            font-weight: 600;
                            color: $color_IMT_Black;
                        }
                        p {
                            color: $color_IMT_Black;
                            font-size: 14px;
                            margin-top: 0.2em;
                        }
                        .uploadDocuments_card_title {
                            margin-bottom: 0.25em;
                            font-weight: 600;
                            color: $color_IMT_Black;
                            // font-size: 1.4em;
                            letter-spacing: -0.5px;
                        }
                        .uploadDocuments_card_subtext {
                            margin: 0;
                            font-size: 14px;
                            color: $color_IMT_Black;
                        }
                        .checkbox {
                            display: flex;
                            align-items: flex-start;
                            flex-direction: row;
                            input {
                                height: inherit;
                                margin-top: 4px;
                            }
                            p {
                                margin: 0;
                                margin-left: 0.5em;
                                font-size: 14px;
                                color: $color_IMT_Black;
                            }
                        }
                    }

                    &.uploadCard {
                        flex-direction: column;
                        cursor: default;
                        // width: 80px;
                        .uploadCard_uploadButton {
                            .uploadCard_uploadButton_icon {
                                margin-right: 1em;
                            }
                            p {
                                margin-bottom: 0;
                            }
                        }
                        .buttonTranlucent {
                            border: none;
                        }
                        .uploadDocuments_card_FileInput_Skeleton {
                            display: flex;
                            width: 100%;
                            justify-content: flex-start;
                            gap: 16px;
                            flex-wrap: wrap;
                            border-radius: 8px;

                            .uploadDocuments_card_FileInput_Skeleton_image {
                                height: 130px;
                                width: 130px;
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                padding: 16px;
                                gap: 6px;
                                background-color: $color_IMT_White;
                                border-radius: 8px;
                            }

                            @media (max-width: 480px) {
                                .uploadDocuments_card_FileInput_Skeleton_image {
                                    width: 100%;
                                    flex-direction: row;
                                    height: auto;
                                    padding-block: 0;
                                }
                            }
                        }
                        .uploadDocuments_card_FileInput_Skeleton_select {
                            width: 100%;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                        }

                        @media (max-width: 480px) {
                            &__fileInput {
                                padding: 0;
                                row-gap: 16px;
                                box-shadow: none;
                            }
                        }
                    }
                    &.popupaction {
                        .uploadDocuments_card_text {
                            gap: 16px;
                            margin-left: 0;
                            h2 {
                                color: $color_IMT_Black;
                                margin-bottom: 0em;
                                font-weight: 600;
                                font-size: 16px;
                            }
                            p {
                                color: $color_IMT_Black;
                                font-size: 14px;
                                margin-bottom: 0;
                                margin-top: 0;
                            }
                            .label {
                                color: $lightwhite;
                            }
                        }
                    }
                    &.downloadCard {
                        justify-content: center;
                    }
                    &.submitted {
                        filter: invert(0.1) sepia(1) saturate(5)
                            hue-rotate(175deg);
                    }

                    &.declaration_patientCard {
                        align-items: flex-start;
                        .declaration_patientCard_profile {
                            background-color: red;
                        }
                        .declaration_patientCard_content {
                            row-gap: 6px;
                            .declaration_patientCard_content_name {
                                width: 100%;
                                display: flex;
                                flex-direction: row;
                                flex-wrap: wrap;
                                justify-content: space-between;
                                align-items: flex-start;
                                p {
                                    margin-bottom: 0;
                                }
                            }
                            .declaration_patientCard_content_text {
                                margin-top: 0;
                                display: flex;
                                flex-direction: column;
                            }
                        }
                    }

                    .loadingOverlay {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background-color: $color_IMT_White;
                        opacity: 1;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        z-index: 1;
                    }

                    // for mobile
                    @media (max-width: 768px) {
                        .uploadDocuments_card_text {
                            h2 {
                                font-size: 14px;
                            }
                            p {
                                font-size: 12px;
                                display: flex;
                                flex-direction: column;
                            }
                        }
                    }
                }

                .fileUpload_Input {
                    padding-left: 0;
                    padding-right: 0;
                    > div {
                        justify-content: flex-start;
                        @media (max-width: 480px) {
                            justify-content: center;
                        }
                    }
                    .fileUpload_Input_image {
                        margin: 0;
                    }
                }
            }
        }

        .claim-upload_footer {
            position: relative;
            // bottom: 0;
            // right: 0;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 16px;
            // padding: 2em 0em;
            margin-top: 8px;
            // background-color: $color_IMT_White;
            border-bottom-left-radius: 16px;
            border-bottom-right-radius: 16px;
            .buttonPrimary {
                pointer-events: none;
                &.active {
                    color: $color_IMT_White;
                    pointer-events: all;
                    // background-color: $color_P500;
                }
                &.disabled {
                    opacity: 0.4;
                    pointer-events: none;
                    color: $color_N50;
                    background-color: $color_N200;
                }
            }

            // for mobile
            @media (max-width: 768px) {
                flex-direction: column;
                align-items: center;
                padding: 0.5em;
                .buttonPrimary {
                    width: 100%;
                    margin-bottom: 0.5em;
                }
            }
        }

        .buttonwhiteBackAbsolute {
            @media (min-width: 768px) {
                background-color: white;
                position: absolute;
                bottom: -35px;
                left: -25px;
                right: -50px;
                width: calc(100% + 50px);
            }
            @media (min-width: 1008px) {
                background-color: white;
                position: absolute;
                bottom: 50px;
                left: -25px;
                right: -50px;
                width: calc(100% + 50px);
            }
        }
    }
    @media (max-width: 1024px) {
        .uploadDocuments {
            // max-height: calc(100vh - calc(2em + 120px));
            .uploadDocuments_navigation {
                .uploadDocuments_navigation_bar {
                    button {
                        display: block;
                    }
                    h1 {
                        margin-left: 0.5em;
                        font-size: 20px;
                    }
                }
                .uploadDocuments_banner {
                    flex-direction: column;
                    .uploadDocuments_banner_image {
                        flex: 1;
                        width: 100%;
                        img {
                            // height: 120px;
                            width: 100%;
                            object-fit: none;
                        }
                    }
                    .uploadDocuments_banner_text {
                        margin: 2em;
                        margin-top: 1em;
                        h2 {
                            font-size: 16px;
                        }
                    }
                }
            }
            .uploadDocuments_footer {
                place-items: stretch;
                padding-inline: 24px;
            }
        }
    }

    .claim-upload_modal {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 337px;
        background-color: $color_IMT_Black;
        border-radius: 1em;
        .claim-upload_modal_header {
            max-width: 400px;
            img {
                border-radius: 1em;
                width: 100%;
                height: 200px;
            }
        }

        .claim-upload_modal_body {
            padding: 2em;
            h2 {
                color: $color_IMT_White;
                font-size: 1.5em;
            }
            p {
                margin-bottom: 0.5em;
            }
        }

        .claim-upload_modal_footer {
            padding: 2em;
        }
    }

    .disableCard {
        pointer-events: none;
        opacity: 0.4;
    }

    .claim_journey_screen_body {
        display: flex;
        flex-direction: column;
        // gap: 12px;
        min-width: 280px !important;
        // padding: 1em;
        flex-grow: 1;
        overflow: auto;
        .claim_journey_screen_body_steps {
            // max-height: 100%;
            display: flex;
            flex-direction: column;
            .line {
                height: 60px;
                width: 5px;
                background-color: $primary;
                margin-left: 37px;
                &:last-child {
                    display: none;
                }
            }
            .line__inactive {
                border-left: 1px dashed $body_off;
                height: 60px;
                width: 2px;
                margin-left: 39px;
                &:last-child {
                    display: none;
                }
            }

            .claim_journey_screen_body_step {
                position: relative;
                display: flex;
                background-color: white;
                align-items: stretch;
                overflow: hidden;
                overflow: visible;
                // padding-block: 1em;
                // border: 1px solid red;
                // &::after {
                //     // opacity: 0.8;
                //     content: "";
                //     position: absolute;
                //     top: 20px;
                //     left: 20px;
                //     transform: translate(-50%, 100%);
                //     width: 1px;
                //     height: 100%;
                //     // background-color: black;
                //     z-index: -1;
                //     border-left: 2px dotted $body_off;
                // }
                &:last-child {
                    &::after {
                        display: none;
                    }
                }
                .claim_journey_screen_body_step_icon {
                    position: absolute;
                    opacity: 0.5;
                    // pointer-events: none;
                    position: relative;
                    width: 16px;
                    height: 16px;
                    margin-inline: 1em;
                    // border-radius: 50%;
                    // border: 1px solid $primary;
                    // background-color: $color_IMT_White;
                    display: grid;
                    place-items: center;
                    z-index: 1;
                    .step_icon_circle {
                        // width: 8px;
                        // height: 8px;
                        border-radius: 50%;
                        // background-color: $primary;
                    }
                }
                .step_card {
                    // min-height: 70px;
                    flex: 1;
                    position: relative;
                    border-radius: 0.2rem;
                    padding: 0rem 0rem;
                    margin-left: 1em;
                    h2 {
                        font-size: 18px;
                        font-weight: 600;
                    }
                    p {
                        margin-bottom: 0.1rem;
                        color: $color_IMT_Black;
                        font-size: 14px;
                        span {
                            font-weight: bold;
                        }
                    }
                }

                &.active {
                    opacity: 1;
                    .claim_journey_screen_body_step_icon {
                        opacity: 1;
                        pointer-events: all;
                    }
                    .step_card {
                        opacity: 1;
                        pointer-events: all;
                    }
                }
                &.done {
                    .claim_journey_screen_body_step_icon {
                        border: none;
                        opacity: 1;
                        img {
                            width: 16px;
                            height: 16px;
                        }
                    }
                }
            }
        }
    }

    .uploadScreenColumn {
        @media (min-width: 1024px) {
            height: calc(100vh - 100px);
            // overflow: hidden;
            margin-bottom: calc(-60px - 2rem);
            &__v2 {
                height: auto;
                margin-bottom: unset;
                width: 100%;
                // overflow: visible;
            }
        }

        .uploadScreenColumn_left {
            @media (min-width: 1024px) {
                height: auto;
            }
        }

        .uploadScreenColumn_right {
            @media (min-width: 1024px) {
                overflow-y: auto;
                &::-webkit-scrollbar {
                    display: none;
                }
            }
        }

        .uploadCard__fileInput {
            width: 100%;
        }
    }
}

.uploadHomePopup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 337px;
    background-color: $color_IMT_White;
    border-radius: 24px;
    &.customerCarePopup {
        padding: 0;
    }
    padding: 2em;
    // padding-top: 5em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    p {
        color: $color_IMT_Black;
        font-size: 14px;
        text-align: center;
        font-weight: 500;
        margin-bottom: 2.5em;
        margin-top: 1em;
    }
}
