@import "../../assets/scss/partials/_pallet.scss";

$height: 60px;
$radius: 12px;

.downloadcenter  .table__searchbar {
    display: flex;
    position: relative;
    justify-content: flex-start;
    max-width: 300px;
    margin-right: 10px;
    align-items: flex-start;
    .search___icon {
        cursor: pointer;
        position: absolute;
        top: 12px;
        right: 10px;
    }
}

.downloadcenter .ant-table-container .iconify--material-symbols{
    color: $primary;
}

.downloadcenter .ant-table-body{
    overflow-y: auto !important;
}

.downloadcenter .table__searchbar .custom-search-input {
    border-radius: 24px !important;
}
