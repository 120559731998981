@import "../../../assets/scss/partials/pallet";

.KmdInvoice {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .KmdInvoice_icici {
        width: 50%;
    }
    .KmdInvoice_content {
        display: flex;
        justify-content: space-between;
    }
    .KmdInvoice_total {
        background-color: $color58;
    }
    background-color: white;
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.039);
    border-radius: 10px;
    > button {
        height: 36px;
    }

    .KmdInvoice_mobile {
        display: none;
    }

    @media (max-width: 768px) {
        gap: 0;
        .KmdInvoice_icici {
            width: 100%;
            height: 56px;
            padding: 8px;
        }
        flex-wrap: wrap;
        flex-direction: row;
        .KmdInvoice_content {
            flex-direction: column;
            display: none;
            padding: 8px;
        }
        :nth-child(2) {
            display: flex;
        }
        :nth-child(5) {
            display: flex;
        }
        justify-content: space-evenly;
        .KmdInvoice_nextButton {
            display: none;
        }
        .KmdInvoice_mobile {
            display: flex;
        }
    }
}
