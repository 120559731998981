@import "../../assets/scss/partials/pallet";

.table-parent {
    .global-btns-container {
        .downloadbutton-table {
            overflow: visible;
            height: 47px !important;
            border-radius: 10px !important;
            box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.041);
        }
        .buttonPrimary{
            border-radius: 10px !important;
        }
    }
    .customCalender_inputWrapper{
        border-radius: 10px !important;
    }
}

.table___main {
    .highlighted-row {
        background-color: $color_N400;
    }

    .ant-table-wrapper .ant-table {
        border-radius: 24px;
    }

    .MuiTabs-root {
        border-radius: 12px !important;
    }

    #demo-customized-button {
        border-radius: 12px !important;
    }

    .table___errorRow {
        border-color: red;
    }

    .ant-space-item>div>div>img {
        cursor: pointer;
    }

    .download___btn {
        // margin-left: 1rem;
        margin-bottom: 0.5rem;
        padding: 24px;
        height: 3em;
        width: 8em;
        // color: darkgreen;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        border: none;
    }

    .display___flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        gap: 10px;

        @media (max-width: 700px) {
            width: 100%;
        }
    }

    #ant__table .ant-table-thead tr .ant-table-cell {
        background-color: $color_N100;
    }

    #ant__table .ant-table-container {
        border-radius: 24px;
        overflow: hidden;
    }

    .ellipsis,
    #ant__table .ant-table-thead th:not(.ant-table-selection-column),
    .ant-table-cell:not(:first-child):not(:nth-child(2)):not(:nth-child(3)) {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 100%;

        // position: relative;
        &:hover {
            padding-top: 0px;
            padding-bottom: 0px;
            overflow: auto;
            text-overflow: initial;

            &::-webkit-scrollbar {
                width: 6px;
            }

            &::-webkit-scrollbar-thumb {
                background-color: $color_N50;
            }

            &::-webkit-scrollbar-track {
                background-color: $color_N400;
            }
        }
    }

    #ant__table .ant-table-thead th {
        height: 40px;
        line-height: 40px;
        /* Ensure the text is vertically centered */
    }

    #ant__table {
        margin-top: 1em;
    }

    .custom__table .ant-table {
        font-family: "Poppins", sans-serif;
    }
}

.dropdown___style {
    font-size: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.table___errorIcon {
    color: red;
    cursor: pointer;
}

.search___container {
    display: flex;
    background-color: $color_IMT_White;
    align-items: center;
    // width: 100%;
    border-radius: 5px;

    input {
        border: none;

        &:focus {
            border: none;
            box-shadow: none;
        }
    }

    >* {
        flex: 1 1 0px !important;
    }

    @media (max-width: 600px) {
        width: 100%;
    }
}

.table__searchbar {
    display: flex;
    justify-content: center;
    align-items: center;

    .search___icon {
        cursor: pointer;
    }
}

.custom-search-input {
    position: relative;
    // width: 300px;
    height: 40px;
    background-color: $color_IMT_White;
    font-size: 16px;
    padding-left: 10px;
    padding-right: 10px;
}

.table___popoverIcon {
    background-color: white;
    padding: 15px 16px;
    border-radius: 40%;
    margin-bottom: 0.6rem;
}
.body-container_v2 {
    .custom-search-input {
        border: 1px solid $color_N400;
    }
}