@import "../../../assets/scss/partials/pallet";

.carepaymentstatuspage {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .carepaymentstatuspage_banner {
        width: 100%;
        height: 80%;
        background: $color_C300;
        border-radius: 16px 16px 0 0;
        padding-top: 24px;
    }
    .carepaymentstatuspage_body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .carepaymentstatuspage_text {
            display: flex;
            flex-direction: column;
            align-items: center;
            h2 {
                color: $color_IMT_Black;
                font-weight: bold;
            }
            p {
                color: $color_IMT_Black;
                font-size: 14px;
                text-align: center;
            }
        }
    }
}
