@import "../../assets/scss/partials/pallet";

.fileUpload_Input {
    position: relative;
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    align-items: center;

    background: $background;
    border-radius: 8px;
    width: 100%;
    border: 1px dashed $color_IMT_Black;

    @media (max-width: 480px) {
        &__files {
            border: none;
            background: transparent;
        }
    }

    > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        h2 {
            margin: 0;
        }
    }

    .fileUpload_Input_drop_overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        background: $background;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        z-index: 1;
        display: none;
        pointer-events: none;
        h2 {
            width: 100%;
            color: $color_N600;
            text-align: center;
        }

        &.fileUpload_Input_Drag_Over {
            display: flex;
        }
    }

    > img {
        max-width: 30px;
        margin-top: auto;
    }
    > :last-child {
        margin-bottom: auto;
        text-align: center;
        padding-inline: 16px;
    }

    .fileUpload_Input_images {
        // display: flex;
        // gap: 1em;
        // flex-wrap: wrap;
        // margin: auto;

        display: flex;
        padding: 16px;
        justify-content: center;
        align-items: flex-start;
        align-content: flex-start;
        gap: 16px;
        flex: 1 0 0;
        align-self: stretch;
        flex-wrap: wrap;

        @media (max-width: 480px) {
            flex-direction: column;
            justify-content: center;
            padding: 0;
        }
    }
    .fileUpload_Input_image {
        position: relative;
        width: 130px;
        height: 130px;
        padding: 16px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        gap: 6px;
        border-radius: 8px;
        background: $color_IMT_White;
        margin: auto;

        // display: flex;
        // width: 130px;
        // height: 130px;
        // max-width: 552px;
        // min-height: 48px;
        // padding: 16px;
        // flex-direction: column;
        // justify-content: center;
        // align-items: center;
        // gap: 6px;
        // background: $color_IMT_White;
        .fileUpload_Input_image_close {
            position: absolute;
            top: 6px;
            right: 6px;
        }

        @media (max-width: 480px) {
            width: 100%;
            height: auto;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            box-shadow: 0px 4px 8px 3px $color_N400;
            &.fileUpload_Input_image_uploadButton {
                background-color: $secondary;
                color: $primary;
                ::first-letter {
                    text-transform: capitalize;
                }
                .fileUpload_Input_image_selected {
                    display: none;
                }
            }
            .fileUpload_Input_image_close {
                position: relative;
                top: 0;
                right: 0;
            }
        }
    }

    .fileUpload_Input_image_selected {
        // position: relative;
        // height: 100px;
        // margin: auto;
        // display: grid;
        // place-items: center;
    }

    .fileUpload_Input_bottom {
        display: flex;
        gap: 1em;
        justify-content: center;
        .fileUpload_Input_prviewLink {
            max-width: 100%;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
        @media (max-width: 480px) {
            flex-grow: 1;
            overflow: hidden;
        }
    }

    .fileUpload_Input_image_selected {
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
            width: 50px;
            height: 50px;
        }
        @media (max-width: 480px) {
            img {
                width: 30px;
                height: 30px;
            }
        }
    }

    .fileUpload_Input_image_buttons {
        display: flex;
        justify-content: flex-end;
        gap: 1em;
        margin-top: auto;
        margin-bottom: 0;
        button {
            width: 8em;
        }
    }

    .fileUpload_Input_Icon {
        display: flex;
        width: 100px;
        height: 100px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 12px;
        margin-bottom: 0;
    }
}
