.kmdAuthWrapper {
    width: 100vw;
    height: 100vh;
    background: linear-gradient(
            180deg,
            #fff 18.02%,
            rgba(255, 255, 255, 0) 99.99%
        ),
        url("https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/New+asset/images/COA+BG+image.png");
    background-size: cover;
}

.kmdAuthentication {
    left: 50%;
    position: absolute;
    top: 50vh;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: min(450px, 95vw);
    text-align: center;
    .kmdAuthentication_header {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        img {
            height: 55px;
        }
        @media (max-width: 470px) {
            flex-wrap: wrap;
            hr {
                display: none;
            }
        }
    }
    .kmdAuthentication_container {
        border: solid 1px #a0a3bd;
        background-color: #fff;
        border-radius: 10px;
        text-align: left;
        // width: 450px;
        height: 305px;
        flex-shrink: 0;

        > h1 {
            text-align: left;
        }
    }
}
