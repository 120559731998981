@import "../../../assets/scss/partials/pallet";

.kmdStep2 {
    .kmdStep2_header {
        display: flex;
        align-items: center;
    }
    .kmdStep2_banner {
        background-color: $color_C300;
        border-radius: 16px 16px 0 0;
        img {
            align-self: flex-start;
            margin-bottom: -10px;
            margin-left: 20px !important;
        }
    }

    .kmdStep2_dataContainer {
        display: flex;
        gap: 50px;
        .kmdStep2_left {
            width: 66.6%;
            padding: 40px;
            @media (max-width: 1049px) and (min-width: 768px) {
                width: 58.3%;
            }
            @media (max-width: 768px) {
                padding: 10px;
            }
        }
        .kmdStep2_right {
            width: 28%;
            margin-top: -30px;
            @media (max-width: 1049px) and (min-width: 768px) {
                width: 41.6%;
            }
            @media (max-width: 768px) {
                padding: 10px;
            }
        }

        @media (max-width: 768px) {
            flex-direction: column-reverse;
            gap: 0;
            .kmdStep2_left {
                width: 100%;
            }
            .kmdStep2_right {
                width: 100%;
                margin-top: 0;
            }
        }
    }

    .kmdStep2_form {
        padding: 0px;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        justify-content: space-between;
        > * {
            width: 45%;
        }
        h1 {
            width: 100%;
        }
        button > * {
            width: 100%;
        }
        @media (max-width: 1049px) {
            > * {
                width: 100%;
            }
        }
    }
    .MuiFormControlLabel-label {
        width: 100%;
    }
    .kmdKycFileInput {
        width: 100%;
    }
    .kmdStep2_GPI_question {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}
