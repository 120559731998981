.kmdOtp {
    display: flex;
    flex-direction: column;
    button {
        width: 100%;
    }
    .kmdOtp_container {
        margin: 0 30px;
    }
}
