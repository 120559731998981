$sizes: -15, -10, -5, 0, 2, 4, 5, 7, 8, 10, 12, 14, 15, 16, 20, 23, 25, 27, 30,
    32, 35, 40, 50, 60, 70, 80;
$directions: "left", "right", "top", "bottom";
$dirSort: "l", "r", "t", "b";

@each $size in $sizes {
    // margins
    .m#{$size} {
        margin: #{$size}px;
    }
    @for $i from 1 through 4 {
        .m#{nth($dirSort,$i)}#{$size} {
            margin-#{nth($directions,$i)}: #{$size}px;
        }
    }
    // importants
    .m#{$size}#{"I"} {
        margin: #{$size}px !important;
    }
    @for $i from 1 through 4 {
        .m#{nth($dirSort,$i)}#{$size}#{"I"} {
            margin-#{nth($directions,$i)}: #{$size}px !important;
        }
    }
    // auto
    .m {
        margin: auto !important;
    }
    @for $i from 1 through 4 {
        .m#{nth($dirSort,$i)} {
            margin-#{nth($directions,$i)}: auto !important;
        }
    }

    // paddings
    .p#{$size} {
        padding: #{$size}px;
    }
    @for $i from 1 through 4 {
        .p#{nth($dirSort,$i)}#{$size} {
            padding-#{nth($directions,$i)}: #{$size}px;
        }
    }
    // importants
    .p#{$size}#{"I"} {
        padding: #{$size}px !important;
    }
    @for $i from 1 through 4 {
        .p#{nth($dirSort,$i)}#{$size}#{"I"} {
            padding-#{nth($directions,$i)}: #{$size}px !important;
        }
    }
}
