// @import "../../assets//scss//partials/common";
@import "./../../../../assets/scss/partials/variables";
@import "./../../../../assets/scss/partials/rem";
@import "./../../../../assets/scss/partials/pallet";

.customerCare {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
        margin: 0;
        padding: 0;
    }
    padding: 20px;
    background-color: $color_IMT_White;
    border-radius: 15px;
    box-shadow: 0px 0px 12px $color_N200;
    display: flex;
    align-items: flex-start;
    // margin-bottom: 12px;
    // width: 320px;

    @media (max-width: 682px) {
        width: 100%;
    }

    &__icon {
        margin-top: 4px;
    }

    .customerCare__container {
        // margin-left: 20px;
        flex-grow: 1;
        .heading {
            color: $headings_font;
            font-weight: 500;
            font-size: 14px;
            // line-height: 24px;
            margin-bottom: 3px;
        }

        .text {
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            justify-content: space-between;
            margin-bottom: 0.4rem;
            word-break: break-word;
            > div {
                flex-direction: column;
            }
            h1 {
                font-size: 1.3rem;
                margin-block: 0.5rem;
                font-weight: bold;
            }
            > p {
                color: $gray-t;
                font-size: 12px;
                line-height: 18px;
                margin-right: rem(9px);
            }
            > img {
                width: 12px;
            }
        }

        .customerCare__container__CTA {
            display: flex;
            justify-content: space-between;
            margin-top: 1rem;

            @media (max-width: 1024px) {
                flex-direction: column;
                gap: 1rem;
                .buttonPrimary {
                    color: $primary;
                    background-color: $color_IMT_White;
                }
                .buttonWhite {
                    color: $color_IMT_White;
                    background-color: $primary;
                }
            }
        }
    }

    .filterDark {
        filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(359deg)
            brightness(95%) contrast(88%);
    }
}
