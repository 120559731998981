@import "../../assets/scss/partials/pallet";

.kmdPaymentComplete {
    background-color: $color_C100;
    border-radius: 16px;
    .kmdPaymentComplete_container {
        background-color: $color_IMT_White;
        margin-right: auto;
        margin-left: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 343px;
        .kmdPaymentComplete_statusImage {
            width: 60px;
        }
        .kmdPaymentComplete_iciciLogo {
            width: 80px;
        }
        border-radius: 16px;

        .kmdPaymentComplete_DetailText {
            text-align: center;
        }
        button {
            width: 100%;
        }
    }
}
