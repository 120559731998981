@import "../../assets/scss/partials/pallet";

.fileUpload_Input2 {
    position: relative;
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    align-items: center;

    width: 100%;

    @media (max-width: 480px) {
        &__files {
            border: none;
            background: transparent;
        }
    }

    > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        h2 {
            margin: 0;
        }
    }

    > img {
        max-width: 30px;
        margin-top: auto;
    }
    > :last-child {
        margin-bottom: auto;
        text-align: center;
        padding-inline: 16px;
    }

    .fileUpload_Input_images {
        // display: flex;
        // gap: 1em;
        // flex-wrap: wrap;
        // margin: auto;

        display: flex;
        padding: 16px;
        padding-top: 24px;
        justify-content: center;
        align-items: flex-start;
        align-content: flex-start;
        gap: 24px;
        flex: 1 0 0;
        align-self: stretch;
        flex-wrap: wrap;

        @media (max-width: 480px) {
            flex-direction: column;
            justify-content: center;
            padding: 0;
            padding-top: 10px;
        }
    }
    .fileUpload_Input_image {
        position: relative;
        width: "100%";
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 6px;
        margin: auto;

        // border: 1px dashed $black;
        .fileUpload_Input_image_close {
            position: absolute;
            top: -6px;
            left: 80px;
            @media (max-width: 480px) {
                left: 60px;
                top: 0px;
            }
        }
    }

    .fileUpload_Input_bottom2 {
        display: flex;
        gap: 1em;
        padding-bottom: 1em;
        justify-content: center;
        .fileUpload_Input_prviewLink {
            max-width: 100%;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
        @media (max-width: 480px) {
            flex-grow: 1;
            overflow: hidden;
        }
    }

    .fileUpload_Input_image_selected {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 10px;
        padding: 10px;
        img {
            width: 80px;
            height: 80px;
        }
        @media (max-width: 480px) {
            img {
                width: 50px;
                height: 50px;
            }
        }
    }

    .fileUpload_Input_image_buttons {
        display: flex;
        justify-content: flex-end;
        gap: 1em;
        margin-top: auto;
        margin-bottom: 0;
        button {
            width: 8em;
        }
    }

    .fileUpload_Input_Icon {
        display: flex;
        width: 100px;
        height: 100px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 12px;
        margin-bottom: 0;
    }
}
