@import "../../assets/scss/partials/pallet";

.customPagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1em;
    gap: 0.7em;

    .customPaginationPrev {
        transform: rotateZ(180deg);
    }
    button {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 32px;
        width: 32px;
        border-radius: 4px;
        // padding: 0.1em;
        // font-size: 1.3em;
        border: none;
        background-color: inherit;
        &:hover {
            background-color: $color_N400;
        }
        &.customPaginationSelected {
            background-color: $color_N50;
        }
        outline: none;
    }
    .customPaginationArrow {
        &:hover {
            background-color: $color_N400;
        }
        cursor: pointer;
    }
}
