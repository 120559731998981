// General
:root {
    --primary: #896bff;
    --filter_primary: invert(28%) sepia(67%) saturate(3124%) hue-rotate(223deg)
        brightness(73%) contrast(160%);
    --lead_table_button: #896bff;
    --checkbox: #896bff;
}

$primary: var(--primary);
$secondary: #efedff;
$active: #29b152;
$negative: #e94e4e;
$red: #ff0909;
$verifying: #ff9c06;
$activeBg: #ccedd6;
$tableRow: #fbfbfb;
$tableHeading: #eaeaea;
// $color_IMT_White: #2db98a;
$lightwhite: #aaa;
// $colock: #f0b7b6;
$background: #f6f6f6;
//--------------------
$body_off: #a0a3bd;

// Text
$headings: #3e3c47;
$common: #888594;
$tableContents: #3e3c47;
$hyperLink: #0786e2;
$cardGradient: linear-gradient(195.75deg, #e2deff -98.36%, #5e47ff 88.97%);

$scroll: #a7b1bc;
$scrollHover: #637381;

//colors
$color58: #ffe0b2;
$color85: #cde1ff;
$color116: #1890ff;

// new colors
$color_Acc_Blue: #e5f3f9;
$color_Acc_Pink: #fbebf0;
$color_Acc_Purple: #e5eafb;
$color_Acc_Yellow: #fcf9e0;
$color_Acc_grey: #f4f4fb;
$color_C100: #f9f4ec;
$color_C300: #f8cca0;
$color_E100: #f9dee3;
$color_E600: #f35168;
$color_E800: #dd2a44;
$color_IMT_Black: #000000;
$color_IMT_Dark: #0f075c;
$color_IMT_White: #ffffff;
$color_N100: #f8f7fa;
$color_N200: #eaeaef;
$color_N400: #d9d7e0;
$color_N50: #f9f9fb;
$color_N600: #888594;
$color_N700: #6f6b7a;
$color_N800: #3e3c47;
$color_N850: #1c1b1f;
$color_N900: #111014;
$color_P100: #e2deff;
$color_P200: #bfb7ff;
$color_P300: #8e82ff;
$color_P500: #5e4dff;
$color_P600: #4434d6;
$color_P700: #2f21ad;
$color_P50: #efedff;
$color_S100: #ccedd6;
$color_S800: #29b152;
$color_Topup_bg: #fbe4d1;
$color_Topup_button: #e8771b;
$color_Topup_text: #be5b0b;
$color_W500: #ffc65a;
$color_W600: #ffbf42;

$filter_primary: var(--filter_primary);

//buttons
$primary_button: $primary;
$secondary_button: $secondary;
$color_IMT_White_button: $color_IMT_White;
$color_IMT_Black_button: $color_IMT_Black;
$error_button: $negative;
//
////Font
$base_font: $common;
$primary_font: $primary;
//$secondary_font
$color_IMT_White_font: $color_IMT_White;
$color_IMT_Black_font: $headings;
$error_font: $negative;
$headings_font: $headings;
//
////HyperLink
//$primary_hyperlink
//
//
////table
$table_header_bg: #eaeaea;
$table_cell_bg: $tableRow;
//
//
////alerts
//$alert_info
//$alert_error
//$alert_pending
//$alert_success
//
////topbar
$active_filter: $filter_primary;
$topbar_logout: $common;
$topbar_logout_filter: none;
//$disable_font
//$active_font
//
////gutter
//$gutter

// tabs
$tab_active: $color_IMT_White;
$tab_active_bg: $primary;
$tab_inactive: $common;
$tab_inactive_bg: $color_IMT_White;
